
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/company/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/company/manager";
import header from "@/vuex/header";
import CommonButton from "@/components/common/Button.vue";
import CommonText from "@/components/common/Text.vue";
import CommonSelect from "@/components/common/Select.vue";

@Component({
  components: {
    CommonButton,
    CommonText,
    CommonSelect,
  }
})
export default class Main extends Mixins(mixinNencho) {
  @Prop() index: number;


  get tmp_care_insurance() {
    return this.m.care_insurances[this.index];
  }
  set tmp_care_insurance(val) {
    this.m.setCareInsurance({index: this.index, care_insurance: val});
  }

  delete_care_insurance() {
    var index = this.m.care_insurance_num > 1 ? (this.index + 1) : '';
    dialog.openConfirmDialog({msg: '介護医療保険'+index+'を削除してよろしいですか？', cancel_button: '削除しない', exec_button: '削除する', func: this.delete_care_insurance_process, args: {}});
  }
  delete_care_insurance_process({}) {
    this.m.setCareInsuranceNum({num: this.m.care_insurance_num - 1});
    this.m.deleteCareInsurance({index: this.index});
  }
}
