
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import { validationLifeInsurance, validationCareInsurance, validationPensionInsurance, validationEarthquakeInsurance, validationSocialInsurance, validationMutualaidInsurance } from '@/validations/nencho_insurance';
import validate from '@/validations/validate';
import validate_api from "@/validations/validate_api";
import CommonProgress from "@/components/common/Progress.vue";
import { VStepper } from "vuetify/lib";
import { VStepperHeader } from "vuetify/lib";
import { VStepperStep } from "vuetify/lib";
import { VDivider } from "vuetify/lib";
import { VStepperItems } from "vuetify/lib";
import { VStepperContent } from "vuetify/lib";
import CommonBigbutton from "@/components/common/Bigbutton.vue";
import NenchoInsuranceLifeNuminput from "@/components/nencho/insurance/life/Numinput.vue";
import NenchoInsuranceLifeInput from "@/components/nencho/insurance/life/Input.vue";
import NenchoInsuranceCareNuminput from "@/components/nencho/insurance/care/Numinput.vue";
import NenchoInsuranceCareInput from "@/components/nencho/insurance/care/Input.vue";
import NenchoInsurancePensionNuminput from "@/components/nencho/insurance/pension/Numinput.vue";
import NenchoInsurancePensionInput from "@/components/nencho/insurance/pension/Input.vue";
import NenchoInsuranceEarthquakeNuminput from "@/components/nencho/insurance/earthquake/Numinput.vue";
import NenchoInsuranceEarthquakeInput from "@/components/nencho/insurance/earthquake/Input.vue";
import NenchoInsuranceSocialNuminput from "@/components/nencho/insurance/social/Numinput.vue";
import NenchoInsuranceSocialInput from "@/components/nencho/insurance/social/Input.vue";
import NenchoInsuranceMutualaidNuminput from "@/components/nencho/insurance/mutualaid/Numinput.vue";
import NenchoInsuranceMutualaidInput from "@/components/nencho/insurance/mutualaid/Input.vue";
import NenchoInsuranceLifeNumdisplay from "@/components/nencho/insurance/life/Numdisplay.vue";
import NenchoInsuranceLifeDisplay from "@/components/nencho/insurance/life/Display.vue";
import NenchoInsuranceCareNumdisplay from "@/components/nencho/insurance/care/Numdisplay.vue";
import NenchoInsuranceCareDisplay from "@/components/nencho/insurance/care/Display.vue";
import NenchoInsurancePensionNumdisplay from "@/components/nencho/insurance/pension/Numdisplay.vue";
import NenchoInsurancePensionDisplay from "@/components/nencho/insurance/pension/Display.vue";
import NenchoInsuranceEarthquakeNumdisplay from "@/components/nencho/insurance/earthquake/Numdisplay.vue";
import NenchoInsuranceEarthquakeDisplay from "@/components/nencho/insurance/earthquake/Display.vue";
import NenchoInsuranceSocialNumdisplay from "@/components/nencho/insurance/social/Numdisplay.vue";
import NenchoInsuranceSocialDisplay from "@/components/nencho/insurance/social/Display.vue";
import NenchoInsuranceMutualaidNumdisplay from "@/components/nencho/insurance/mutualaid/Numdisplay.vue";
import NenchoInsuranceMutualaidDisplay from "@/components/nencho/insurance/mutualaid/Display.vue";


@Component({
  components: {
    CommonProgress,
    VStepper,
    VStepperHeader,
    VStepperStep,
    VDivider,
    VStepperItems,
    VStepperContent,
    CommonBigbutton,
    NenchoInsuranceLifeNuminput,
    NenchoInsuranceLifeInput,
    NenchoInsuranceCareNuminput,
    NenchoInsuranceCareInput,
    NenchoInsurancePensionNuminput,
    NenchoInsurancePensionInput,
    NenchoInsuranceEarthquakeNuminput,
    NenchoInsuranceEarthquakeInput,
    NenchoInsuranceSocialNuminput,
    NenchoInsuranceSocialInput,
    NenchoInsuranceMutualaidNuminput,
    NenchoInsuranceMutualaidInput,
    NenchoInsuranceLifeNumdisplay,
    NenchoInsuranceLifeDisplay,
    NenchoInsuranceCareNumdisplay,
    NenchoInsuranceCareDisplay,
    NenchoInsurancePensionNumdisplay,
    NenchoInsurancePensionDisplay,
    NenchoInsuranceEarthquakeNumdisplay,
    NenchoInsuranceEarthquakeDisplay,
    NenchoInsuranceSocialNumdisplay,
    NenchoInsuranceSocialDisplay,
    NenchoInsuranceMutualaidNumdisplay,
    NenchoInsuranceMutualaidDisplay,
  }
})
export default class Main extends Mixins(mixinNencho) {
  save = false;
  
  update_nencho_insurance() {
    this.m.updateNenchoInsurance({nencho_id: this.nencho_id, employee_id: this.employee_id, query: this.query});
  }

  get is_nenkin() {
    return this.m.social_insurances.filter(social_insurance => social_insurance.kind == 0).length != 0;
  }
  get is_nenkin_kikin() {
    return this.m.social_insurances.filter(social_insurance => social_insurance.kind == 1).length != 0;
  }

  get step_number() {
    return this.insurance_step_number;
  }

  get step() {
    return this.m.step;
  }
  set step(val) {
    this.m.setStep(val);
  }

  async next_step(step) {
    if (step < this.step) {
      this.step = step
    } else if (this.step_number['life'] && this.step == this.step_number['life']['step']) {
      var error = [];
      if (this.m.life_insurance_num == 1) {
        error = error.concat(validationLifeInsurance(this.m.life_insurances[0], 0));
      } else {
        for (var index = 0; index < this.m.life_insurance_num; index++) {
          error = error.concat(validationLifeInsurance(this.m.life_insurances[index], index + 1));
        }
      }
      if (validate(error)) {
        this.step = step;
      }
    } else if (this.step_number['care'] && this.step == this.step_number['care']['step']) {
      var error = [];
      if (this.m.care_insurance_num == 1) {
        error = error.concat(validationCareInsurance(this.m.care_insurances[0], 0));
      } else {
        for (var index = 0; index < this.m.care_insurance_num; index++) {
          error = error.concat(validationCareInsurance(this.m.care_insurances[index], index + 1));
        }
      }
      if (validate(error)) {
        this.step = step;
      }
    } else if (this.step_number['pension'] && this.step == this.step_number['pension']['step']) {
      var error = [];
      if (this.m.pension_insurance_num == 1) {
        error = error.concat(validationPensionInsurance(this.m.pension_insurances[0], 0));
      } else {
        for (var index = 0; index < this.m.pension_insurance_num; index++) {
          error = error.concat(validationPensionInsurance(this.m.pension_insurances[index], index + 1));
        }
      }
      if (validate(error)) {
        this.step = step;
      }
    } else if (this.step_number['earthquake'] && this.step == this.step_number['earthquake']['step']) {
      var error = [];
      if (this.m.earthquake_insurance_num == 1) {
        error = error.concat(validationEarthquakeInsurance(this.m.earthquake_insurances[0], 0));
      } else {
        for (var index = 0; index < this.m.earthquake_insurance_num; index++) {
          error = error.concat(validationEarthquakeInsurance(this.m.earthquake_insurances[index], index + 1));
        }
      }
      if (validate(error)) {
        this.step = step;
      }
    } else if (this.step_number['social'] && this.step == this.step_number['social']['step']) {
      var error = [];
      if (this.m.social_insurance_num == 1) {
        error = error.concat(validationSocialInsurance(this.m.social_insurances[0], 0));
      } else {
        for (var index = 0; index < this.m.social_insurance_num; index++) {
          error = error.concat(validationSocialInsurance(this.m.social_insurances[index], index + 1));
        }
      }
      if (validate(error)) {
        this.step = step;
      }
    } else if (this.step_number['mutualaid'] && this.step == this.step_number['mutualaid']['step']) {
      var error = [];
      if (this.m.mutualaid_insurance_num == 1) {
        error = error.concat(validationMutualaidInsurance(this.m.mutualaid_insurances[0]));
      }
      if (validate(error)) {
        this.step = step;
      }
    } else {
      this.step = step;
    }
  }

  get is_finish_insurance() {
    return this.query.is_finish_insurance;
  }

  @Watch('is_finish_insurance')
  scroll_top(newval, oldval) {
    if (newval !== undefined && oldval === undefined) {
      this.scrollTop();
    }
  }


  async created() {
    this.save = false;
    if (this.is_finish_insurance === undefined) {
      this.m.setStep(0.1);
      if (this.employee_yearly.nencho_master.is_finish_insurance == 1) {
        this.save = true;
        this.m.setLifeInsuranceNum({num: this.insurance_yearly.life_insurances.length});
        this.m.setCareInsuranceNum({num: this.insurance_yearly.care_insurances.length});
        this.m.setPensionInsuranceNum({num: this.insurance_yearly.pension_insurances.length});
        this.m.setEarthquakeInsuranceNum({num: this.insurance_yearly.earthquake_insurances.length});
        this.m.setSocialInsuranceNum({num: this.insurance_yearly.social_insurances.length});
        this.m.setMutualaidInsuranceNum({num: this.insurance_yearly.mutualaid_insurances.length});
        this.m.setLifeInsurances({life_insurances: this.insurance_yearly.life_insurances});
        this.m.setCareInsurances({care_insurances: this.insurance_yearly.care_insurances});
        this.m.setPensionInsurances({pension_insurances: this.insurance_yearly.pension_insurances});
        this.m.setEarthquakeInsurances({earthquake_insurances: this.insurance_yearly.earthquake_insurances});
        this.m.setSocialInsurances({social_insurances: this.insurance_yearly.social_insurances});
        this.m.setMutualaidInsurances({mutualaid_insurances: this.insurance_yearly.mutualaid_insurances});
        this.m.setStep(this.step_number['confirm']['step']);
      } else {
        this.save = await this.m.getSave({nencho_id: this.nencho_id, employee_id: this.employee_id, kind: 'insurance'});
        if (this.save !== null) {
          this.m.setLifeInsurances({life_insurances: this.save.content.life_insurances});
          this.m.setCareInsurances({care_insurances: this.save.content.care_insurances});
          this.m.setPensionInsurances({pension_insurances: this.save.content.pension_insurances});
          this.m.setEarthquakeInsurances({earthquake_insurances: this.save.content.earthquake_insurances});
          this.m.setSocialInsurances({social_insurances: this.save.content.social_insurances});
          this.m.setMutualaidInsurances({mutualaid_insurances: this.save.content.mutualaid_insurances});
          this.m.setLifeInsuranceNum({num: this.save.content.life_insurances.length});
          this.m.setCareInsuranceNum({num: this.save.content.care_insurances.length});
          this.m.setPensionInsuranceNum({num: this.save.content.pension_insurances.length});
          this.m.setEarthquakeInsuranceNum({num: this.save.content.earthquake_insurances.length});
          this.m.setSocialInsuranceNum({num: this.save.content.social_insurances.length});
          this.m.setMutualaidInsuranceNum({num: this.save.content.mutualaid_insurances.length});
          this.m.setStep(this.save.content.step);
        } else {
          this.m.setLifeInsuranceNum({num: 0});
          this.m.setCareInsuranceNum({num: 0});
          this.m.setPensionInsuranceNum({num: 0});
          this.m.setEarthquakeInsuranceNum({num: 0});
          this.m.setSocialInsuranceNum({num: 0});
          this.m.setMutualaidInsuranceNum({num: 0});
          this.m.setLifeInsurances({life_insurances: []});
          this.m.setCareInsurances({care_insurances: []});
          this.m.setPensionInsurances({pension_insurances: []});
          this.m.setEarthquakeInsurances({earthquake_insurances: []});
          this.m.setSocialInsurances({social_insurances: []});
          this.m.setMutualaidInsurances({mutualaid_insurances: []});
        }
      }
    } else {
      this.save = true;
    }
  }
}
