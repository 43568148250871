import * as util from "@/util";
import * as vali from "@/validations/validationUtil";


export function validationLifeInsurance(life_insurance, index = null) {
  var error = [];

  var prefix = '';
  if (index === 0) {
    prefix = '一般の生命保険の';
  } else if (index > 0) {
    prefix = '一般の生命保険'+index+'の';
  }

  error.push(life_insurance.ins_name !== null || prefix+'保険会社等の名称を入力してください');
  error.push(life_insurance.type !== null || prefix+'新旧の区分を選択してください');
  error.push(life_insurance.money !== null || prefix+'保険料等の金額を入力してください');
  error.push(life_insurance.money === null || vali.is_number(life_insurance.money) || prefix+'保険料等の金額は半角数字で入力してください');
  error.push(life_insurance.money === null || !vali.is_number(life_insurance.money) || vali.is_zeroplus(life_insurance.money) || prefix+'保険料等の金額は0以上の値を入力してください');

  return error;
}

export function validationCareInsurance(care_insurance, index = null) {
  var error = [];

  var prefix = '';
  if (index === 0) {
    prefix = '介護医療保険の';
  } else if (index > 0) {
    prefix = '介護医療保険'+index+'の';
  }

  error.push(care_insurance.ins_name !== null || prefix+'保険会社等の名称を入力してください');
  error.push(care_insurance.money !== null || prefix+'保険料等の金額を入力してください');
  error.push(care_insurance.money === null || vali.is_number(care_insurance.money) || prefix+'保険料等の金額は半角数字で入力してください');
  error.push(care_insurance.money === null || !vali.is_number(care_insurance.money) || vali.is_zeroplus(care_insurance.money) || prefix+'保険料等の金額は0以上の値を入力してください');

  return error;
}

export function validationPensionInsurance(pension_insurance, index = null) {
  var error = [];

  var prefix = '';
  if (index === 0) {
    prefix = '個人年金保険の';
  } else if (index > 0) {
    prefix = '個人年金保険'+index+'の';
  }

  error.push(pension_insurance.ins_name !== null || prefix+'保険会社等の名称を入力してください');
  error.push(pension_insurance.type !== null || prefix+'新旧の区分を選択してください');
  error.push(pension_insurance.money !== null || prefix+'保険料等の金額を入力してください');
  error.push(pension_insurance.money === null || vali.is_number(pension_insurance.money) || prefix+'保険料等の金額は半角数字で入力してください');
  error.push(pension_insurance.money === null || !vali.is_number(pension_insurance.money) || vali.is_zeroplus(pension_insurance.money) || prefix+'保険料等の金額は0以上の値を入力してください');

  return error;
}

export function validationEarthquakeInsurance(earthquake_insurance, index = null) {
  var error = [];

  var prefix = '';
  if (index === 0) {
    prefix = '地震保険の';
  } else if (index > 0) {
    prefix = '地震保険'+index+'の';
  }

  error.push(earthquake_insurance.ins_name !== null || prefix+'保険会社等の名称を入力してください');
  error.push(earthquake_insurance.type !== null || prefix+'地震保険又は旧長期損害保険区分を選択してください');
  error.push(earthquake_insurance.money !== null || prefix+'保険料等の金額を入力してください');
  error.push(earthquake_insurance.money === null || vali.is_number(earthquake_insurance.money) || prefix+'保険料等の金額は半角数字で入力してください');
  error.push(earthquake_insurance.money === null || !vali.is_number(earthquake_insurance.money) || vali.is_zeroplus(earthquake_insurance.money) || prefix+'保険料等の金額は0以上の値を入力してください');

  return error;
}

export function validationSocialInsurance(social_insurance, index = null) {
  var error = [];

  var prefix = '';
  if (index === 0) {
    prefix = '社会保険の';
  } else if (index > 0) {
    prefix = '社会保険'+index+'の';
  }

  error.push(social_insurance.kind !== null || prefix+'社会保険の種類を選択してください');
  error.push(social_insurance.kind != 4 || social_insurance.kind_name !== null || prefix+'社会保険の名称を入力してください');
  error.push(social_insurance.money !== null || prefix+'保険料等の金額を入力してください');
  error.push(social_insurance.money === null || vali.is_number(social_insurance.money) || prefix+'保険料等の金額は半角数字で入力してください');
  error.push(social_insurance.money === null || !vali.is_number(social_insurance.money) || vali.is_zeroplus(social_insurance.money) || prefix+'保険料等の金額は0以上の値を入力してください');

  return error;
}

export function validationMutualaidInsurance(mutualaid_insurance) {
  var error = [];

  var prefix = '小規模企業共済掛金等の';

  error.push(mutualaid_insurance.maintenance_money !== null || prefix+'独立行政法人中小企業基盤整備機構の共済契約の掛金の金額を入力してください');
  error.push(mutualaid_insurance.maintenance_money === null || vali.is_number(mutualaid_insurance.maintenance_money) || prefix+'独立行政法人中小企業基盤整備機構の共済契約の掛金の金額は半角数字で入力してください');
  error.push(mutualaid_insurance.maintenance_money === null || !vali.is_number(mutualaid_insurance.maintenance_money) || vali.is_zeroplus(mutualaid_insurance.maintenance_money) || prefix+'独立行政法人中小企業基盤整備機構の共済契約の掛金の金額は0以上の値を入力してください');
  error.push(mutualaid_insurance.company_pension_money !== null || prefix+'確定拠出年金法に規定する企業型年金加入者掛金の金額を入力してください');
  error.push(mutualaid_insurance.company_pension_money === null || vali.is_number(mutualaid_insurance.company_pension_money) || prefix+'確定拠出年金法に規定する企業型年金加入者掛金の金額は半角数字で入力してください');
  error.push(mutualaid_insurance.company_pension_money === null || !vali.is_number(mutualaid_insurance.company_pension_money) || vali.is_zeroplus(mutualaid_insurance.company_pension_money) || prefix+'確定拠出年金法に規定する企業型年金加入者掛金の金額は0以上の値を入力してください');
  error.push(mutualaid_insurance.personal_pension_money !== null || prefix+'確定拠出年金法に規定する個人型年金加入者掛金の金額を入力してください');
  error.push(mutualaid_insurance.personal_pension_money === null || vali.is_number(mutualaid_insurance.personal_pension_money) || prefix+'確定拠出年金法に規定する個人型年金加入者掛金の金額は半角数字で入力してください');
  error.push(mutualaid_insurance.personal_pension_money === null || !vali.is_number(mutualaid_insurance.personal_pension_money) || vali.is_zeroplus(mutualaid_insurance.personal_pension_money) || prefix+'確定拠出年金法に規定する個人型年金加入者掛金の金額は0以上の値を入力してください');
  error.push(mutualaid_insurance.disability_money !== null || prefix+'心身障害者扶養共済制度に関する契約の掛金の金額を入力してください');
  error.push(mutualaid_insurance.disability_money === null || vali.is_number(mutualaid_insurance.disability_money) || prefix+'心身障害者扶養共済制度に関する契約の掛金の金額は半角数字で入力してください');
  error.push(mutualaid_insurance.disability_money === null || !vali.is_number(mutualaid_insurance.disability_money) || vali.is_zeroplus(mutualaid_insurance.disability_money) || prefix+'心身障害者扶養共済制度に関する契約の掛金の金額は0以上の値を入力してください');

  return error;
}