
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/company/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/company/manager";
import header from "@/vuex/header";
import Earning from "@/entities/Earning";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Main extends Mixins(mixinNencho) {
}
