
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';

@Component({
  components: {
  }
})
export default class Main extends Mixins(mixinNencho) {
  @Prop() index: number;

  get tmp_social_insurance() {
    return this.m.social_insurances[this.index];
  }
}
