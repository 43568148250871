var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"infotable_wrap"},[_c('span',{staticClass:"boxsubhead underbox"},[_c('span',[_vm._v("介護医療保険")]),(_vm.m.care_insurance_num > 1)?_c('span',[_vm._v(_vm._s(_vm.index + 1))]):_vm._e()]),_c('table',{staticClass:"underbox"},[_vm._m(0),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.tmp_care_insurance.ins_name))])])]),_c('table',[_vm._m(1),_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("number_format")(_vm.tmp_care_insurance.money))+"円")])])]),(_vm.tmp_care_insurance.kind !== null)?_c('table',[_vm._m(2),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.tmp_care_insurance.kind))])])]):_vm._e(),(_vm.tmp_care_insurance.span !== null)?_c('table',[_vm._m(3),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.util.insurance_span_opt[_vm.tmp_care_insurance.span]))])])]):_vm._e(),(_vm.tmp_care_insurance.contractor_name !== null)?_c('table',[_vm._m(4),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.tmp_care_insurance.contractor_name))])])]):_vm._e(),(_vm.tmp_care_insurance.receiver_name !== null)?_c('table',[_vm._m(5),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.tmp_care_insurance.receiver_name))])])]):_vm._e(),(_vm.tmp_care_insurance.relationship !== null)?_c('table',[_vm._m(6),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.tmp_care_insurance.relationship))])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("保険会社等の名称")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("保険料等の金額")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("保険等の種類")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("保険期間又は年金支払期間")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("保険等の契約者の名前")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("保険金等の受取人の名前")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("上記の続柄")])])
}]

export { render, staticRenderFns }