
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/company/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/company/manager";
import header from "@/vuex/header";
import Earning from "@/entities/Earning";
import CommonSelect from "@/components/common/Select.vue";

@Component({
  components: {
    CommonSelect,
  }
})
export default class Main extends Mixins(mixinNencho) {

  change_mutualaid_insurance_num(val) {
    console.log(val);
    if (val == 0) {
      this.m.deleteMutualaidInsurance({index: 0});
      this.m.setMutualaidInsuranceNum({num: 0});
    } else {
      this.m.setMutualaidInsurance({
        index: 0,
        mutualaid_insurance: {
          id: null,
          nencho_id: null,
          employee_id: null,
          maintenance_money: null,
          company_pension_money: null,
          personal_pension_money: null,
          disability_money: null,
        }
      });
      this.m.setMutualaidInsuranceNum({num: 1});
    }
  }
}
