
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/company/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/company/manager";
import header from "@/vuex/header";
import Earning from "@/entities/Earning";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Main extends Mixins(mixinNencho) {
  add_pension_insurance() {
    this.m.setPensionInsurance({
      index: this.m.pension_insurance_num,
      pension_insurance: {
        id: null,
        nencho_id: null,
        employee_id: null,
        ins_name: null,
        type: 1,
        money: null,
        kind: null,
        span: null,
        contractor_name: null,
        receiver_name: null,
        relationship: null,
      }
    });
    this.m.setPensionInsuranceNum({num: this.m.pension_insurance_num + 1});
  }
}
