
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Check extends Vue {
  @Prop() value: any;
  @Prop({default: false}) multi: boolean;
  @Prop({default: 'null'}) this_value: any;
  @Prop({default: ''}) label: string;
  @Prop({default: ''}) after_label: string;
  @Prop() name: string;

  once_input: boolean = false;//lazy

  @Watch('value')
  on_change_value(newval, oldval) {
    this.change_checked_value(newval);
  }

  get use_this_value() {
    return Number(this.this_value);
  }

  get checked_value() {
    if (this.value === null) {
      return false;
    } else {
      if (this.multi) {
        return this.value.indexOf(this.this_value) !== -1;
      } else {
        return Number(this.value);
      }
    }
  }
  set checked_value(val) {
    console.log(val);
    this.change_checked_value(val);
    this.once_input = true;
  }

  @Emit('input')
  change_checked_value(val) {
    if (this.multi && Array.isArray(this.value)) {
      if (val) {
        var tmp = this.value;
        tmp.push(this.this_value);
        return tmp;
      } else {
        return this.value.filter(value => value != this.this_value);
      }
    } else {
      return val;
    }
  }
}
