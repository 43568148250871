
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/company/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/company/manager";
import header from "@/vuex/header";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    CommonText,
  }
})
export default class Main extends Mixins(mixinNencho) {
  get tmp_mutualaid_insurance() {
    return this.m.mutualaid_insurances[0];
  }
  set tmp_mutualaid_insurance(val) {
    this.m.setMutualaidInsurance({index: 0, mutualaid_insurance: val});
  }
}
