
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/company/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/company/manager";
import header from "@/vuex/header";
import CommonButton from "@/components/common/Button.vue";
import CommonText from "@/components/common/Text.vue";
import CommonRadio from "@/components/common/Radio.vue";
import CommonSelect from "@/components/common/Select.vue";

@Component({
  components: {
    CommonButton,
    CommonText,
    CommonRadio,
    CommonSelect,
  }
})
export default class Main extends Mixins(mixinNencho) {
  @Prop() index: number;


  get tmp_life_insurance() {
    return this.m.life_insurances[this.index];
  }
  set tmp_life_insurance(val) {
    this.m.setLifeInsurance({index: this.index, life_insurance: val});
  }

  delete_life_insurance() {
    var index = this.m.life_insurance_num > 1 ? (this.index + 1) : '';
    dialog.openConfirmDialog({msg: '一般の生命保険'+index+'を削除してよろしいですか？', cancel_button: '削除しない', exec_button: '削除する', func: this.delete_life_insurance_process, args: {}});
  }
  delete_life_insurance_process({}) {
    this.m.setLifeInsuranceNum({num: this.m.life_insurance_num - 1});
    this.m.deleteLifeInsurance({index: this.index});
  }
}
